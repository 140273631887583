<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0 bg-login">

      <b-row class="navbar-auth-custom px-0 mx-0 col-sm-12 w-100">
        <b-col
          :cols="isEmediaPlatform ? 12 : 7"
        >
          <!-- Brand logo-->
          <a
            v-if="isEcomdyPlatform"
            href="https://ecomdymedia.com/"
            target="_blank"
          >
            <brand-logo />
          </a>
          <brand-logo v-else />
          <!-- /Brand logo-->
        </b-col>
      </b-row>
      <!-- Login-->
      <b-col
        :lg="isEmediaPlatform ? 12 : 7"
        class="d-flex align-items-center auth-bg px-2 p-lg-5 pt-1"
      >
        <b-col
          :sm="isEmediaPlatform ? 12 : 8"
          md="7"
          :lg="isEmediaPlatform ? 4 : 7"
          class=" mx-auto max-width form-login"
        >
          <b-card-title
            title-tag="h1"
            class="font-medium mb-2 d-flex justify-content-center"
          >
            {{ $t('login.textSignIn') }}
          </b-card-title>
          <b-card-text class="mb-3 text-center description theme-text-secondary">
            {{ $t('login.textGuide') }}
          </b-card-text>

          <!-- form -->
          <validation-observer
            ref="loginValidation"
            v-slot="{ invalid }"
          >
            <b-form
              class="auth-login-form mt-2 mx-auto"
              @submit.prevent
            >
              <!-- email -->
              <b-form-group
                class="my-2 form-email"
              >
                <div class="d-flex justify-content-between">
                  <label class="form-label">
                    {{ $t('common.labelEmail') }}
                  </label>
                </div>
                <validation-provider
                  #default="{ errors }"
                  vid="email"
                  rules="required"
                  :name="$t('common.labelEmail')"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      v-model.trim="userEmail"
                      id="login-email"
                      data-testid="login-email"
                      tabindex="1"
                      class="input-height form-input"
                      name="login-email"
                      :placeholder="$t('login.placeholderEmail')"
                      :state="errors.length > 0 ? false:null"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- password -->
              <b-form-group class="form-password">
                <div class="d-flex justify-content-between">
                  <label class="form-label">
                    {{ $t('common.labelPassword') }}
                  </label>
                </div>
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  :name="$t('common.labelPassword')"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      v-model.trim="password"
                      id="login-password"
                      data-testid="login-password"
                      tabindex="2"
                      class="form-control-merge input-height form-input"
                      name="login-password"
                      :placeholder="$t('login.placeholderPassword')"
                      :type="passwordFieldType"
                      :state="errors.length > 0 ? false:null"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <div class="navbar-title px-0 underline">
                <b-link
                  class="cover"
                  :to="{name:'forgot-password'}"
                >
                  <span
                    id="link-forgot-password"
                    data-testid="link-forgot-password"
                  >
                    {{ $t('login.linkForgotPassword') }}
                  </span>
                </b-link>
              </div>
              <!-- forgot password -->

              <!-- submit buttons -->
              <btn-loading
                id="btn-login"
                data-testid="btn-login"
                class="btn-login input-height d-flex justify-content-center align-items-center font-medium txt-color"
                tabindex="4"
                type="submit"
                variant-convert="btn-submit"
                block
                :disabled="invalid"
                :loading="loading"
                @click="validationForm"
              >
                {{ $t('login.btnSignIn') }}
              </btn-loading>

              <!-- <div class="divider">
                <div class="divider-text text-placeholder">
                  Or login with social network
                </div>
              </div>

              <btn-loading
                id="btn-login"
                class="btn-login btn-fb input-height d-flex justify-content-center align-items-center font-medium txt-color"
                tabindex="4"
                type="submit"
                variant="secondary"
                block
                :loading="loadingLoginFb"
                @click="handleLoginFacebook"
              >
                <b-img
                  class="icons-tick text-facebook"
                  fluid
                  alt="guide"
                  :src="require('@/assets/images/pages/auth/icon-fb.svg')"
                />
                Sign in with Facebook
              </btn-loading>

              <btn-loading
                id="btn-login"
                class="btn-login btn-gg input-height d-flex justify-content-center align-items-center font-medium"
                tabindex="4"
                type="submit"
                variant="secondary"
                block
                :loading="loadingLoginGg"
                @click="handleLoginGoogle"
              >
                <b-img
                  class="icons-tick text-google"
                  fluid
                  alt="guide"
                  :src="require('@/assets/images/pages/auth/icon-gg.svg')"
                />
                Sign in with Google
              </btn-loading> -->
            </b-form>
          </validation-observer>

          <b-card-text class="text-center mt-2 theme-text-secondary">
            <span>{{ $t('login.dontHaveAnyAccount') }}</span>
            <b-link :to="{name:'register'}">
              <span
                id="btn-register-now"
                data-testid="btn-register-now"
              >&nbsp;
                {{ $t('login.registerNow') }}
              </span>
            </b-link>
          </b-card-text>

        </b-col>
      </b-col>

      <!-- Left Text-->
      <left-image v-if="!isEmediaPlatform" />
      <!-- /Left Text-->

      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BCardText, BCardTitle, BForm,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { toastification } from '@core/mixins/toast'
import { $themeConfig } from '@themeConfig'
import { createNamespacedHelpers } from 'vuex'
import BrandLogo from '@/layouts/components/BrandLogo.vue'
import LeftImage from '@/layouts/components/LeftImage.vue'
import BtnLoading from '@/layouts/components/BtnLoading.vue'
import partnerMixin from '@/mixins/partnerMixin'
import stepGuideMixin from '@/mixins/stepGuideMixin'
import { AuthService } from '@/services'
import envMixin from '@/mixins/envMixin'

const { mapGetters, mapActions } = createNamespacedHelpers('auth')

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BCardText,
    BCardTitle,
    BForm,

    // components
    LeftImage,
    BrandLogo,
    BtnLoading,

    // validation
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility, toastification, partnerMixin, stepGuideMixin, envMixin],
  data() {
    return {
      password: '',
      userEmail: '',

      // validation rulesimport store from '@/store/index'
      required,
      email,
      loadingLoginFb: false,
      loadingLoginGg: false,
    }
  },

  setup() {
    // App Name
    const { appName } = $themeConfig.app
    return {
      appName,
    }
  },

  computed: {
    ...mapGetters(['user', 'status', 'loading', 'message']),
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },

  methods: {
    ...mapActions(['login', 'getAccountInfo']),
    async validationForm() {
      const success = await this.$refs.loginValidation.validate()
      if (success) {
        const params = {
          email: this.userEmail,
          password: this.password,
        }
        await this.login({ params })
        if (this.status) {
          this.getInfoUser()
        } else {
          this.toastFailure(this.message)
        }
      }
    },

    async getInfoUser() {
      await this.getAccountInfo()
      if (this.isPartner) {
        await localStorage.setItem('partnerView', 'true')
        window.location.href = '/partner-management'
      } else {
        window.location.href = '/'
      }
    },

    handleLoginFacebook() {
      this.loadingLoginFb = true
      try {
        AuthService.loginFacebook()
        this.loadingLoginFb = false
      } catch {
        this.loadingLoginFb = false
        this.toastFailure('Login fail. Please try again!')
      }
    },

    handleLoginGoogle() {
      this.loadingLoginGg = true
      try {
        AuthService.loginGoogle()
        this.loadingLoginGg = false
      } catch {
        this.loadingLoginGg = false
        this.toastFailure('Login fail. Please try again!')
      }
    },
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/pages/page-auth.scss';
</style>
<style lang="scss" scoped>
.auth-wrapper .auth-bg {
  background-color: #f8f8fa;
}

  .form-email {
    margin-bottom: 1.5rem;
  }

  .form-password {
    margin-bottom: 2rem;
  }

  .btn-login {
    border-radius: 100px;
    margin: 2rem 0;
  }

  .divider {
    margin: 2.5rem 0;

    .divider-text {
      font-size: 1rem;
    }
  }

  .btn-fb {
    background-color: #fff !important;
    color: #000 !important;
    margin-bottom: 12px;
    &:hover {
      box-shadow: 0 8px 25px -8px rgba(0, 0, 0, 0.3) !important;
    }
  }

  .btn-gg {
    background-color: #fff !important;
    color: #000 !important;
    margin-top: 12px;
    &:hover {
      box-shadow: 0 8px 25px -8px rgba(0, 0, 0, 0.3) !important;
    }
  }

  .form-login {
    padding: 0 1rem;
  }

  .btn-block {
    border: 1px solid #E0E0E5 !important;
  }

  .input-height {
    height: 50px;
  }

  .txt-color {
    color: #FCF7F8
  }

  .cover {
    color: #16213E;
  }

  .icons-tick {
    margin-right: 12px;
  }

  @media (max-width: 767px) {
    .form-login {
      .description {
        line-height: 24px;
        margin-bottom: 2rem !important;
      }
    }
    .navbar-auth-custom {
      width: calc(100%);
    }
    .card-title {
      padding-top: 2.5rem;
    }
    .card-text:last-child {
      margin-bottom: 2.2rem;
    }
  }

.bg-login {
  background-color: #f8f8fa;
}
</style>
